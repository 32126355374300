import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Profile from "./components/Profile";
import RefactoredProfile from "./components/RefactoredProfile";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/profile-details/:userId" component={RefactoredProfile} />
      </Switch>
    </Router>
  );
}

export default App;
