import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import logo from "../assets/leap_og_image_new.png";
import location from "../assets/gps.svg";
import placeholder from "../assets/placeholder.png";
import logoBlack from "../assets/leap_og_image_new.png";
import { LinkPreview } from "@dhaiwat10/react-link-preview";

const HighlightedContent = function (props) {
  const className = ["text highlighted-content"];
  className.push(props.className);
  const computedText =
    props.highlightedText &&
    props.text.replace(
      "%variable.highlightedText%",
      '<span class="golden">' + props.highlightedText + "</span>"
    );
  const innerHTML = {
    __html: computedText,
  };
  return (
    <div
      className={className.join(" ")}
      dangerouslySetInnerHTML={innerHTML}
    ></div>
  );
};

const Intro = ({ name }) => (
  <HighlightedContent
    className="text size"
    text="hi, i'm %variable.highlightedText%"
    highlightedText={`${name}!`}
  />
);

const Separator = function (props) {
  var classes = ["separator-generic"];
  if (props.className) classes.push(props.className);
  return <div {...props} className={classes.join(" ")} />;
};

function RefactoredProfile() {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [x, setX] = useState(0);
  const [chList, setChList] = useState([]);
  const params = useParams();
  const userId = params.userId;
  const spacing = 3;

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `https://main-app-prod-api.leap.club/v1/website/profileDetails/${userId}`
        );
        setProfileData(data);
        if (data?.chList.length > 8) {
          setChList(data?.chList?.slice(0, 8));
        } else {
          setChList(data.chList);
        }
        setX(data?.chList?.length > 8 ? data?.chList?.length - 8 : null);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError(true);
        setLoading(false);
      }
    })();
  }, []);

  // useEffect(() => {},[])

  const showMore = () => {
    setChList(profileData.chList);
  };
  const showLess = () => {
    setChList(profileData?.chList?.slice(0, 8));
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            bottom: "0",
            left: "0",
            right: "0",
            margin: "auto",
            width: "100px",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      ) : error ? (
        <div style={{ textAlign: "center" }}>
          <div>
            <a href="https://www.leap.club">
              <img src={logoBlack} width="100px" alt="black logo" />
            </a>
          </div>
          <div>an error occurred. click here to navigate to main site</div>
        </div>
      ) : (
        <div>
          <div id="header">
            <Grid container alignItems="center">
              <Grid item xs={3} sm={2} md={1}>
                <a
                  href="https://www.leap.club"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={logo} width="60px" alt="leap logo" />
                </a>
              </Grid>
              <Grid item xs={9} sm={10} md={11} className="headerFont">
                <span> a community led professional network for women. </span>
                <a
                  href="https://www.leap.club#waitlist"
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: "inline", color: "white" }}
                >
                  join
                </a>
                <span> the waitlist now.</span>
              </Grid>
            </Grid>
          </div>

          <Grid container style={{ padding: "5px" }}>
            {profileData?.image && (
              <Grid item xs={12} sm={3} style={{ paddingTop: "1%" }}>
                <img
                  style={{
                    borderRadius: "5%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "10px",
                  }}
                  src={profileData.image}
                  alt="profile"
                  width="80%"
                />
              </Grid>
            )}

            <Grid item xs={12} sm={8}>
              <div style={{ padding: "3%" }}>
                {profileData?.name && (
                  <div
                    style={{ fontSize: "40px", fontWeight: "600" }}
                    className="font"
                  >
                    <Intro name={profileData?.name} />
                  </div>
                )}

                <div>
                  <div>
                    {profileData?.headline && (
                      <div
                        style={{
                          fontSize: "16px",
                          marginBottom: "10px",
                          marginTop: "5px",
                        }}
                      >
                        <span> {profileData?.headline} </span>
                      </div>
                    )}
                    {profileData?.city && (
                      <div
                        style={{
                          fontSize: "16px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={location}
                          width="15px"
                          height="15px"
                          alt="location"
                        />
                        {profileData?.city}
                      </div>
                    )}
                  </div>
                </div>

                <br />
                <Separator className="mbot40" />
                <br />

                {profileData?.introduction && (
                  <div>
                    <div>
                      <span
                        className="text"
                        style={{ fontSize: "16px", lineHeight: "1.5" }}
                      >
                        {profileData?.introduction}
                      </span>
                    </div>
                    <br />
                    <Separator />
                    <br />
                  </div>
                )}

                {profileData?.chList && profileData?.chList.length > 0 && (
                  <div>
                    <span
                      className="text"
                      style={{ fontWeight: "600", fontSize: "18px" }}
                    >
                      clubhouses i'm part of
                    </span>
                    <br />
                    <br />
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      spacing={spacing}
                      id="lessArray"
                    >
                      {chList &&
                        chList.map((ch) => (
                          <Grid item key={ch.name}>
                            <div
                              style={{
                                textAlign: "center",
                                background: "rgba(207, 162, 91, 0.2)",
                                borderRadius: "20px",
                                padding: "10px",
                                fontSize: "16px",
                              }}
                            >
                              {" "}
                              {ch.name}{" "}
                            </div>
                          </Grid>
                        ))}
                    </Grid>
                    <br />
                    {profileData?.chList.length > 8 && (
                      <p
                        id="moreButton"
                        onClick={chList.length === 8 ? showMore : showLess}
                        style={{ cursor: "pointer" }}
                      >
                        {chList.length === 8 ? (
                          <u>+{x} more</u>
                        ) : (
                          <u>show less</u>
                        )}
                      </p>
                    )}
                    <br />
                    <br />
                    <Separator />
                    <br />
                  </div>
                )}

                {profileData?.industryTags?.length > 0 ? (
                  <div>
                    <div>
                      <span
                        className="text"
                        style={{ fontWeight: "600", fontSize: "18px" }}
                      >
                        directories i feature in
                      </span>
                      <br />
                      <br />
                      <Grid container xs={12} sm={12} md={12} spacing={spacing}>
                        {profileData?.industryTags?.map(
                          (industryTag, index) => {
                            return (
                              <Grid item key={index}>
                                <div
                                  style={{
                                    textAlign: "center",
                                    background: "rgba(207, 162, 91, 0.2)",
                                    borderRadius: "20px",
                                    padding: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {" "}
                                  {industryTag}{" "}
                                </div>
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    </div>
                    <br />
                    <Separator />
                    <br />
                  </div>
                ) : (
                  <></>
                )}

                {profileData?.featuredLinks?.length > 0 && (
                  <div>
                    <div className="mbot20">
                      <span
                        className="text"
                        style={{ fontWeight: "600", fontSize: "18px" }}
                      >
                        featured links
                      </span>
                    </div>
                    <div>
                      <br />
                      <Grid container xs={12} spacing={spacing}>
                        {profileData?.featuredLinks.map((link, index) => (
                          <Grid item xs={12} sm={6} key={index}>
                            <Grid container sm={12}>
                              <Grid
                                item
                                sm={11}
                                style={{
                                  backgroundColor: "#f5ecde6b",
                                  padding: "20px",
                                }}
                              >
                                <LinkPreview
                                  key={index}
                                  openInNewTab
                                  url={
                                    link.startsWith("http")
                                      ? link
                                      : `https://${link}`
                                  }
                                  fallbackImageSrc={placeholder}
                                  margin={1}
                                  fallback={
                                    <a
                                      href={
                                        link.startsWith("http")
                                          ? link
                                          : `https://${link}`
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{
                                        width: "100%",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      <Card
                                        variant="outlined"
                                        sx={{ px: 2, py: 1, cursor: "pointer" }}
                                      >
                                        <CardMedia
                                          component="img"
                                          height="150"
                                          image={placeholder}
                                          alt="link preview image"
                                        />
                                        <CardContent
                                          sx={{
                                            height: "100%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            color="text.secondary"
                                          >
                                            {link.startsWith("http")
                                              ? link
                                              : `https://${link}`}
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    </a>
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                    <br />
                    <Separator />
                    <br />
                  </div>
                )}

                {profileData?.topSkills?.length > 0 && (
                  <div>
                    <div className="mbot20">
                      <span
                        className="text"
                        style={{ fontWeight: "600", fontSize: "18px" }}
                      >
                        my top skill sets
                      </span>
                    </div>
                    <div>
                      <br />
                      <Grid container xs={12} sm={12} md={12} spacing={spacing}>
                        {profileData?.topSkills?.map((skill, index) => (
                          <Grid item key={index}>
                            <div
                              style={{
                                textAlign: "center",
                                background: "rgba(207, 162, 91, 0.2)",
                                borderRadius: "20px",
                                padding: "10px",
                                fontSize: "16px",
                              }}
                            >
                              {" "}
                              {skill}{" "}
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                    <br />
                    <Separator />
                    <br />
                  </div>
                )}

                {profileData?.experience && (
                  <div>
                    <span
                      className="highlighted-content"
                      style={{ fontSize: "18px" }}
                    >
                      years of work experience:
                    </span>
                    &nbsp;
                    <span className="text">{profileData.experience}</span>
                    <br />
                    <br />
                    <Separator />
                    <br />
                  </div>
                )}

                {profileData?.previousWorkExperience?.length > 0 && (
                  <div>
                    <div>
                      <div>
                        <span style={{ fontWeight: "600", fontSize: "18px" }}>
                          previous work experience
                        </span>
                      </div>
                      <div>
                        <br />
                        {profileData?.previousWorkExperience?.map(
                          (exp, index) => (
                            <div
                              style={{
                                backgroundColor: "#f5ecde6b",
                                padding: "15px 10px 10px 5px",
                                marginBottom: "10px",
                              }}
                              key={index}
                            >
                              <div
                                style={{ fontSize: "17px", fontWeight: "bold" }}
                              >
                                {exp.designation}
                              </div>
                              <div style={{ fontSize: "17px" }}>
                                {exp.company}
                              </div>
                              <div style={{ fontSize: "16px" }}>
                                {exp.duration}
                              </div>
                              <br />
                              <div style={{ fontSize: "16px", color: "grey" }}>
                                {exp.brief}
                              </div>
                              <br />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <Separator />
                    <br />
                  </div>
                )}

                <div style={{ fontSize: "16px", marginBottom: "8px" }}>
                  {profileData?.undergrad && (
                    <div>
                      <span
                        className="highlighted-content"
                        style={{ fontSize: "18px" }}
                      >
                        college:
                      </span>
                      &nbsp;
                      <span className="text">{profileData.undergrad}</span>
                    </div>
                  )}
                  {profileData?.postgrad !== "n/a" &&
                    profileData?.postgrad !== "na" &&
                    profileData?.postgrad !== undefined && (
                      <div style={{ marginTop: "8px" }}>
                        <span
                          className="highlighted-content"
                          style={{ fontSize: "18px" }}
                        >
                          grad school:
                        </span>
                        &nbsp;
                        <span className="text ">{profileData.postgrad}</span>
                      </div>
                    )}
                </div>

                {profileData?.companyAdmire?.length > 0 && (
                  <div style={{ marginBottom: "8px" }}>
                    <span
                      className="highlighted-content"
                      style={{ fontSize: "18px" }}
                    >
                      companies i admire:
                    </span>
                    &nbsp;{" "}
                    <span className="text">
                      {profileData?.companyAdmire
                        ?.map((company) => company)
                        .join(", ")}
                    </span>
                  </div>
                )}

                {profileData?.hobbies?.length > 0 && (
                  <div style={{ marginBottom: "8px" }}>
                    <span
                      className="highlighted-content"
                      style={{ fontSize: "18px" }}
                    >
                      hobbies:
                    </span>
                    &nbsp;{" "}
                    <span className="text">
                      {profileData?.hobbies?.map((hobby) => hobby).join(", ")}
                    </span>
                  </div>
                )}

                {profileData?.leader && (
                  <div style={{ marginBottom: "8px" }}>
                    <span
                      className="highlighted-content"
                      style={{ fontSize: "18px" }}
                    >
                      my hero:
                    </span>
                    &nbsp;
                    <span className="text">{profileData.leader}</span>
                  </div>
                )}

                {(profileData?.undergrad ||
                  profileData?.postgrad !== "n/a" ||
                  profileData?.companyAdmire?.length !== 0 ||
                  profileData.hobbies.length !== 0 ||
                  profileData.leader) && (
                  <div>
                    <br />
                    <Separator className="mbot40" />
                    <br />
                  </div>
                )}

                {profileData?.goals?.length > 0 && (
                  <div>
                    <div>
                      <span style={{ fontWeight: "600", fontSize: "18px" }}>
                        my current goals
                      </span>
                    </div>
                    <div>
                      <br />
                      <Grid container xs={12} sm={12} md={12} spacing={spacing}>
                        {profileData?.goals?.map((goal, index) => (
                          <Grid item key={index}>
                            <div
                              style={{
                                textAlign: "center",
                                background: "rgba(207, 162, 91, 0.2)",
                                borderRadius: "20px",
                                padding: "10px",
                                fontSize: "16px",
                              }}
                            >
                              {" "}
                              {goal}{" "}
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                    <br />
                    <Separator className="mbot30" />
                    <br />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default RefactoredProfile;
