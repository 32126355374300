import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import axios from "axios";
import logo from "../assets/leap_og_image_new.png";
import logoBlack from "../assets/leap_og_image_new.png";
import location from "../assets/gps.svg";
import "../App.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router";
import placeholder from "../assets/placeholder.png";
import { getLinkPreview, getPreviewFromContent } from "link-preview-js";

function Profile() {
  const [element, setElement] = useState(
    <div
      style={{
        position: "absolute",
        top: "50%",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto",
        width: "100px",
      }}
    >
      <CircularProgress color="black" />
    </div>
  );

  // getLinkPreview("http://raginidas.medium.com/").then(
  //   function (metadata) {
  //     // success handler
  //     console.log(metadata);
  //   },
  //   function (error) {
  //     // failure handler
  //     console.log(error);
  //   }
  // );
  const spacing = 3;
  const params = useParams();
  let x = 0;
  const showMore = () => {
    document.getElementById("moreButton").style.display = "none";
    document.getElementById("lessButton").style.display = "flex";
    document.getElementById("moreArray").style.display = "flex";
    document.getElementById("lessArray").style.display = "none";
  };
  const showLess = () => {
    document.getElementById("moreButton").style.display = "flex";
    document.getElementById("lessButton").style.display = "none";
    document.getElementById("moreArray").style.display = "none";
    document.getElementById("lessArray").style.display = "flex";
  };
  const HighlightedContent = function (props) {
    const className = ["text highlighted-content"];
    className.push(props.className);
    const computedText =
      props.highlightedText &&
      props.text.replace(
        "%variable.highlightedText%",
        '<span class="golden">' + props.highlightedText + "</span>"
      );
    const innerHTML = {
      __html: computedText,
    };
    return (
      <div
        className={className.join(" ")}
        dangerouslySetInnerHTML={innerHTML}
      ></div>
    );
  };
  const Intro = ({ name }) => (
    <HighlightedContent
      className="text size"
      text="hi, i'm %variable.highlightedText%"
      highlightedText={`${name}!`}
    />
  );
  const Separator = function (props) {
    var classes = ["separator-generic"];
    if (props.className) classes.push(props.className);
    return <div {...props} className={classes.join(" ")} />;
  };

  let featuredLinksWithMetadata = [];

  useEffect(() => {
    (async () => {
      let data = null;
      let userId = params.userId;
      try {
        data = await axios.get(
          `https://main-app-prod-api.leap.club/v1/website/profileDetails/${userId}`
        );
      } catch (e) {
        console.log(e);
        setElement(
          <div style={{ textAlign: "center" }}>
            <div>
              <a href="https://www.leap.club">
                <img src={logoBlack} width="100px" />
              </a>
            </div>
            <div>click here to navigate to main site</div>
          </div>
        );
        return;
      }
      data = data.data;
      data.company = data.companyAdmire;
      data.hobby = data.hobbies.join(", ");
      const more = data.chList;
      let less = [];
      less = data.chList.slice(0, 8);
      // for (let i = 0; i < 8; i++) {
      //   less.push(data.chList[i]);
      // }
      x = data?.chList?.length > 8 ? data?.chList?.length - 8 : null;

      for (let i = 0; i < data?.featuredLinks?.length; i++) {
        const response = await axios.get(
          `https://jsonlink.io/api/extract?url=${data.featuredLinks[i]}`
        );
        featuredLinksWithMetadata.push(response.data);
      }

      const industryTags = data.industryTags;
      setElement(
        <div>
          <div id="header">
            <Grid container alignItems="center">
              <Grid item xs={3} sm={2} md={1}>
                <a href="https://www.leap.club" target="_blank">
                  <img src={logo} width="60px" />
                </a>
              </Grid>
              <Grid item xs={9} sm={10} md={11} className="headerFont">
                <span> a community led professional network for women. </span>
                <a
                  href="https://www.leap.club#waitlist"
                  target="_blank"
                  style={{ display: "inline", color: "white" }}
                >
                  join
                </a>
                <span> the waitlist now.</span>
              </Grid>
            </Grid>
          </div>
          <Grid container style={{ padding: "5px" }}>
            {data.image && (
              <Grid item xs={12} sm={3} style={{ paddingTop: "1%" }}>
                <img
                  style={{
                    borderRadius: "5%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "10px",
                  }}
                  src={data.image}
                  alt="Image"
                  width="80%"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={8}>
              <div style={{ padding: "3%" }}>
                {data.name && (
                  <div
                    style={{ fontSize: "40px", fontWeight: "600" }}
                    class="font"
                  >
                    <Intro name={data.name} />
                  </div>
                )}
                <div>
                  <div>
                    {data.headline && (
                      <div
                        style={{
                          fontSize: "16px",
                          marginBottom: "10px",
                          marginTop: "5px",
                        }}
                      >
                        <span> {data.headline} </span>
                      </div>
                    )}
                    {data.city && (
                      <div style={{ fontSize: "16px" }}>
                        <img src={location} width="15px" height="15px" />
                        {data.city}
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <Separator className="mbot40" />
                <br />
                {data.introduction && (
                  <div>
                    <div>
                      <span
                        className="text"
                        style={{ fontSize: "16px", lineHeight: "1.5" }}
                      >
                        {data.introduction}
                      </span>
                    </div>
                    <br />
                    <Separator />
                    <br />
                  </div>
                )}

                {data.chList && data.chList.length > 0 && (
                  <div>
                    <span
                      className="text"
                      style={{ fontWeight: "600", fontSize: "18px" }}
                    >
                      clubhouses i'm part of
                    </span>
                    <br />
                    <br />
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      spacing={spacing}
                      id="lessArray"
                    >
                      {less.length > 0 &&
                        less.map((ch) => (
                          <Grid item>
                            <div
                              style={{
                                textAlign: "center",
                                background: "rgba(207, 162, 91, 0.2)",
                                borderRadius: "20px",
                                padding: "10px",
                                fontSize: "16px",
                              }}
                            >
                              {" "}
                              {ch?.name ? ch.name : ""}{" "}
                            </div>
                          </Grid>
                        ))}
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      spacing={spacing}
                      id="moreArray"
                    >
                      {more.map((ch) => (
                        <Grid item>
                          <div
                            style={{
                              textAlign: "center",
                              background: "rgba(207, 162, 91, 0.2)",
                              borderRadius: "20px",
                              padding: "10px",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            {ch.name}{" "}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                    <br />
                    <a
                      id="moreButton"
                      onClick={showMore}
                      style={{ cursor: "pointer" }}
                    >
                      <u>show more</u>
                    </a>
                    <a
                      id="lessButton"
                      onClick={showLess}
                      style={{ cursor: "pointer" }}
                    >
                      <u>show less</u>
                    </a>
                    <br />
                    <br />
                    <Separator />
                    <br />
                  </div>
                )}

                {data?.industryTags && data?.industryTags ? (
                  <div>
                    <div>
                      <span
                        className="text"
                        style={{ fontWeight: "600", fontSize: "18px" }}
                      >
                        directories i feature in
                      </span>
                      <br />
                      <br />
                      <Grid container xs={12} sm={12} md={12} spacing={spacing}>
                        {industryTags?.map((industryTag, index) => {
                          return (
                            <Grid item key={index}>
                              <div
                                style={{
                                  textAlign: "center",
                                  background: "rgba(207, 162, 91, 0.2)",
                                  borderRadius: "20px",
                                  padding: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                {" "}
                                {industryTag}{" "}
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                    <br />
                    <Separator />
                    <br />
                  </div>
                ) : (
                  <></>
                )}
                {data?.featuredLinks?.length > 0 && (
                  <div>
                    <div className="mbot20">
                      <span
                        className="text"
                        style={{ fontWeight: "600", fontSize: "18px" }}
                      >
                        featured links
                      </span>
                    </div>
                    <div>
                      <br />
                      <Grid container xs={12} spacing={spacing}>
                        {featuredLinksWithMetadata?.map((link) => (
                          <Grid item xs={12} sm={6}>
                            <Grid container sm={12}>
                              <Grid
                                item
                                sm={11}
                                style={{
                                  backgroundColor: "#f5ecde6b",
                                  padding: "20px",
                                }}
                              >
                                <a href={link.url} className="link">
                                  {link.images && (
                                    <div
                                      style={{
                                        textAlign: "center",
                                        height: "150px",
                                        width: "100%",
                                      }}
                                    >
                                      <img
                                        src={link.images[0]}
                                        height="150px"
                                        // width="150px"
                                      />
                                    </div>
                                  )}
                                  {!link.images && (
                                    <div>
                                      <img
                                        src={placeholder}
                                        height="150px"
                                        width="200px"
                                      />
                                    </div>
                                  )}
                                  <br />
                                  {link.title && (
                                    <div
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        height: "20px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {link.title}
                                    </div>
                                  )}
                                  {link.description && (
                                    <div
                                      style={{
                                        fontSize: "16px",
                                        height: "18px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {link.description}
                                    </div>
                                  )}
                                </a>
                              </Grid>
                              <Grid item sm={1}></Grid>
                            </Grid>
                          </Grid>
                        ))}

                        {/* {data.featuredLinks.map((link) => (
                          <Grid item xs={12} sm={6}>
                            <Grid container sm={12}>
                              <Grid
                                item
                                sm={11}
                                style={{
                                  backgroundColor: "#f5ecde6b",
                                  padding: "20px",
                                }}
                              >
                                <a href={link} className="link">
                                  {link["og:image"] && (
                                    <div
                                      style={{
                                        textAlign: "center",
                                        height: "150px",
                                        width: "100%",
                                      }}
                                    >
                                      <img
                                        src={link["og:image"]}
                                        height="150px"
                                      />
                                    </div>
                                  )}
                                  {!link["og:image"] && (
                                    <div>
                                      <img
                                        src={placeholder}
                                        height="150px"
                                        width="200px"
                                      />
                                    </div>
                                  )}
                                  <br />
                                  {link["og:title"] && (
                                    <div
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        height: "20px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {link["og:title"]}
                                    </div>
                                  )}
                                  {link["og:description"] && (
                                    <div
                                      style={{
                                        fontSize: "16px",
                                        height: "18px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {link["og:description"]}
                                    </div>
                                  )}
                                </a>
                              </Grid>
                              <Grid item sm={1}></Grid>
                            </Grid>
                          </Grid>
                        ))}
                        */}
                      </Grid>
                    </div>
                    <br />
                    <Separator />
                    <br />
                  </div>
                )}
                {data?.topSkills?.length !== 0 && (
                  <div>
                    <div className="mbot20">
                      <span
                        className="text"
                        style={{ fontWeight: "600", fontSize: "18px" }}
                      >
                        my top skill sets
                      </span>
                    </div>
                    <div>
                      <br />
                      <Grid container xs={12} sm={12} md={12} spacing={spacing}>
                        {data?.topSkills?.map((skill) => (
                          <Grid item>
                            <div
                              style={{
                                textAlign: "center",
                                background: "rgba(207, 162, 91, 0.2)",
                                borderRadius: "20px",
                                padding: "10px",
                                fontSize: "16px",
                              }}
                            >
                              {" "}
                              {skill}{" "}
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                    <br />
                    <Separator />
                    <br />
                  </div>
                )}
                {data?.experience && (
                  <div>
                    <span
                      className="highlighted-content"
                      style={{ fontSize: "18px" }}
                    >
                      years of work experience:
                    </span>
                    &nbsp;
                    <span className="text">{data.experience}</span>
                    <br />
                    <br />
                    <Separator />
                    <br />
                  </div>
                )}
                {data?.previousWorkExperience?.length !== 0 && (
                  <div>
                    <div>
                      <div>
                        <span style={{ fontWeight: "600", fontSize: "18px" }}>
                          previous work experience
                        </span>
                      </div>
                      <div>
                        <br />
                        {data?.previousWorkExperience?.map((exp) => (
                          <div
                            style={{
                              backgroundColor: "#f5ecde6b",
                              padding: "15px 10px 10px 5px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{ fontSize: "17px", fontWeight: "bold" }}
                            >
                              {exp.designation}
                            </div>
                            <div style={{ fontSize: "17px" }}>
                              {exp.company}
                            </div>
                            <div style={{ fontSize: "16px" }}>
                              {exp.duration}
                            </div>
                            <br />
                            <div style={{ fontSize: "16px", color: "grey" }}>
                              {exp.brief}
                            </div>
                            <br />
                          </div>
                        ))}
                      </div>
                    </div>
                    <Separator />
                    <br />
                  </div>
                )}
                <div style={{ fontSize: "16px", marginBottom: "8px" }}>
                  {data?.undergrad && (
                    <div>
                      <span
                        className="highlighted-content"
                        style={{ fontSize: "18px" }}
                      >
                        college:
                      </span>
                      &nbsp;
                      <span className="text">{data.undergrad}</span>
                    </div>
                  )}
                  {data?.postgrad !== "n/a" &&
                    data?.postgrad !== "na" &&
                    data?.postgrad !== undefined && (
                      <div style={{ marginTop: "8px" }}>
                        <span
                          className="highlighted-content"
                          style={{ fontSize: "18px" }}
                        >
                          grad school:
                        </span>
                        &nbsp;
                        <span className="text ">{data.postgrad}</span>
                      </div>
                    )}
                </div>
                {data?.companyAdmire?.length !== 0 && (
                  <div style={{ marginBottom: "8px" }}>
                    <span
                      className="highlighted-content"
                      style={{ fontSize: "18px" }}
                    >
                      companies i admire:
                    </span>
                    &nbsp;
                    <span className="text">{data.company}</span>
                  </div>
                )}
                {data?.hobbies?.length !== 0 && (
                  <div style={{ marginBottom: "8px" }}>
                    <span
                      className="highlighted-content"
                      style={{ fontSize: "18px" }}
                    >
                      hobbies:
                    </span>
                    &nbsp;
                    <span className="text">{data.hobby}</span>
                  </div>
                )}
                {data?.leader && (
                  <div style={{ marginBottom: "8px" }}>
                    <span
                      className="highlighted-content"
                      style={{ fontSize: "18px" }}
                    >
                      my hero:
                    </span>
                    &nbsp;
                    <span className="text">{data.leader}</span>
                  </div>
                )}
                {(data?.undergrad ||
                  data?.postgrad !== "n/a" ||
                  data?.companyAdmire?.length !== 0 ||
                  data?.hobbies.length !== 0 ||
                  data?.leader) && (
                  <div>
                    <br />
                    <Separator className="mbot40" />
                    <br />
                  </div>
                )}
                {data?.goals?.length !== 0 && (
                  <div>
                    <div>
                      <span style={{ fontWeight: "600", fontSize: "18px" }}>
                        my current goals
                      </span>
                    </div>
                    <div>
                      <br />
                      <Grid container xs={12} sm={12} md={12} spacing={spacing}>
                        {data?.goals?.map((goal) => (
                          <Grid item>
                            <div
                              style={{
                                textAlign: "center",
                                background: "rgba(207, 162, 91, 0.2)",
                                borderRadius: "20px",
                                padding: "10px",
                                fontSize: "16px",
                              }}
                            >
                              {" "}
                              {goal}{" "}
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                    <br />
                    <Separator className="mbot30" />
                    <br />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      );
      if (data?.chList?.length !== 0) {
        document.getElementById("lessButton").style.display = "none";
        document.getElementById("moreArray").style.display = "none";
        if (x) {
          document.getElementById("moreButton").innerHTML = "+" + x + " more";
          document.getElementById("moreButton").style.textDecoration =
            "underline";
        } else {
          document.getElementById("moreButton").style.display = "none";
        }
      }
    })();
  }, []);
  return <div>{element}</div>;
}

export default Profile;
